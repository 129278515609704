
import { mapGetters, mapActions } from 'vuex';
import ParagraphSkeleton from '../../../shared/components/paragraph-skeleton.vue';
import parkingCard from './parking-card.vue';

export default {
  name: 'app-parking-view-page',
  computed: {
    isDark() {
      return this.$q.dark.isActive;
    },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'parking/view/record',
      current: 'branch/view/branch',
      loading: 'parking/view/loading',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar';
    },
  },
  data() {
    return {
      parkingNames: [
        'جراج كبار السن ',
        'جراج جرين كونور',
        ' جراج صالة الجمباز ',
        'جراج السكواش',
        'جراج السباحة',
        ' جراج الجيم الدولي',
        ' جراج مركز الخدمات',
        ' جراج Delight',
        ' جراج Bus',
        'جراج توشكى',
        ' جراج الهوكي',
        'جراج فورسية Vila Haris',
        ' جراج ملاهي الدولفين',
        ' جراج مروة الشربيني',
        ' جراج ملاعب أسوان',
      ],
    };
  },
  watch: {
     current() {
       this.doFind(this.current);
    },
  },
  components: {
    parkingCard,
    ParagraphSkeleton,
  },

  methods: {
    ...mapActions({
      doFind: 'parking/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    this.current ? await this.doFind(this.current) : ``;
    console.log('record', this.record);
  },
};
